<template>
  <div class="park">
    <div class="park-rou" v-if="isParkNew">
      <ul>
        <li class="pda">
          <div class="con" @click="toJumpT(1)">
            <h2 class="con-title">PDA</h2>
            <p class="con-es">Physical Data Archives</p>
            <p class="con-ch">幼儿足球测试体系</p>
          </div>
        </li>
        <li class="pes">
          <div class="con" @click="toJumpT(2)">
            <h2 class="con-title">PES</h2>
            <p class="con-es">Physical Evalution System</p>
            <p class="con-ch">幼儿足球评估体系</p>
          </div>
        </li>
      </ul>
      <div class="preball">
        <img src="../../../../assets/img/pda/Pic_FootBall.png" alt="" />
      </div>
    </div>
    <div class="new-park" v-else>
      <div class="park-img park-pos">
        <div class="news" @click="clickTab(0)">开始使用</div>
        <img src="../../../../assets/img/product/Pic_PT_PDA.png" alt="" />
      </div>
      <div class="park-img park-pos">
        <div class="version" @click="bindToPes()">开始使用</div>
        <img src="../../../../assets/img/product/Pic_PES.png" alt="" />
      </div>
    </div>
    <div class="pop" v-if="showSelect">
      <div class="pop-content">
        <div class="close" @click="cancelTab">
          <img src="../../../../assets/img/product/Icon_Close.png" alt="" />
        </div>
        <div class="top_bg">
          <div class="text">版本选择</div>
        </div>
        <div class="row">
          <div class="flex column">
            <img src="../../../../assets/img/product/Pic_PT_New.png" alt="" />
            <div class="news" @click="bindToTzTest">标准体质测试</div>
          </div>
          <div class="flex column">
            <img src="../../../../assets/img/product/Pic_PT_Old.png" alt="" />
            <div class="old" @click="bindToYdTest">标准运动测试</div>
          </div>
          <div class="flex column">
            <img src="../../../../assets/img/product/Pic_PT_Old.png" alt="" />
            <div class="old" @click="bindToYdTestHigh">高级运动测试</div>
            <div class="kx_text">可选</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isParkNew: false, // 判断是否显示新版旧版
      currentComp: "padContent",
      isActive: "1",
      menu: [
        {
          name: "PDA测试",
          value: "1",
          comp: "padContent",
        },
        {
          name: "PES报告",
          value: "2",
          comp: "pesContent",
        },
      ],
      showSelect: false,
      showType: 0,
    };
  },
  methods: {
    toJumpT(val) {
      if (val === 1) {
        if (this.$store.state.userInfo.teacherId) {
          this.$router.push({
            path: "/admin/testconter/padContent",
          });
        } else {
          this.$notice({
            message: "请使用教师账号登录",
          }).isShow();
        }
      } else {
        this.$router.push({
          path: "/admin/testconter/pesContent",
        });
        // this.$notice({
        //   message: "新系统PES报告优化中，请您在旧版系统查看",
        // }).isShow();
      }
    },
    clickTab(index) {
      this.showType = index;
      this.showSelect = true;
    },
    cancelTab() {
      this.showSelect = false;
    },
    // 跳转旧版
    bindToVersion() {
      this.showSelect = false;
      console.log(111111111111);
      if (this.showType == 0) {
        let token = JSON.parse(localStorage.getItem("userInfo")).token;
        window.open(process.env.VUE_APP_NODE + "?token=" + token);
      } else {
        this.$router.push({
          path: "/PESHome",
        });
      }
    },
    bindToTzTest() {
      let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(process.env.VUE_APP_NODE + "?token=" + token + "&testType=2");
    },
    bindToYdTest() {
      let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(process.env.VUE_APP_NODE2 + "?token=" + token);
    },
    bindToYdTestHigh() {
      let token = JSON.parse(localStorage.getItem("userInfo")).token;
      window.open(process.env.VUE_APP_NODE + "?token=" + token + "&testType=1");
    },
    bindToPes() {
      this.$router.push({
        path: "/pesNew",
      });
    },
    bindToVersionOld() {
      this.showSelect = false;
      if (this.showType == 0) {
        if (this.$store.state.userInfo.teacherId) {
          this.$router.push({
            path: "/admin/testconter/padContent",
          });
        } else {
          this.$notice({
            message: "请使用教师账号登录",
          }).isShow();
        }
      } else {
        this.$router.push({
          path: "/admin/testconter/pesContent",
        });
      }
    },
    bindToNews() {
      this.$router.push({
        path: "/PESHome",
      });
    },
  },
  mounted() {
    // this.$notice({
    //   type: "success",
    //   message: "全新界面设计，添加更多功能设计，快来体验吧～",
    //   func: true,
    // }).isShow();
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.park-rou {
  position: relative;
  ul {
    display: flex;
    li {
      width: 50%;
      height: 440px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
      a {
        display: block;
      }
      .con {
        cursor: pointer;
      }
      .con-title {
        font-size: 68px;
        font-family: Arial Black;
        font-weight: 400;
        color: #ffffff;
      }
      .con-es {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
        margin-bottom: 25px;
      }
      .con-ch {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .pda {
      background: url(../../../../assets/img/pda/Pic_Bg_CS_PDA.png) no-repeat;
      background-size: 100% 100%;
    }
    .pda:hover {
      background: url(../../../../assets/img/pda/Pic_Bg_CS_PDA_H.png) no-repeat;
      background-size: 100% 100%;
    }
    .pes {
      background: url(../../../../assets/img/pda/Pic_Bg_Cs_PES.png) no-repeat;
      background-size: 100% 100%;
    }
    .pes:hover {
      background: url(../../../../assets/img/pda/Pic_Bg_Cs_PES_H.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.preball {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 280px;
    height: 282px;
  }
}
.new-park {
  .park-img {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .park-pos {
    position: relative;
  }
  .version:hover {
    background: linear-gradient(232deg, #668ddd, #5380d9);
    box-shadow: 0px 3px 6px 0px rgba(103, 149, 235, 0.39);
  }
  .news:hover {
    background: linear-gradient(50deg, #dd3b44, #f74f58);
    box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
  }
  .version {
    width: 160px;
    height: 42px;
    background: linear-gradient(232deg, #4e78ca, #395da4);
    box-shadow: 0px 3px 6px 0px #a9c6ff;
    border-radius: 21px;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
    display: flex;
    font-size: 21px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 741px;
    bottom: 183px;
  }
  .news {
    width: 160px;
    height: 42px;
    background: linear-gradient(50deg, #b83037, #d24249);
    box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
    border-radius: 21px;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
    display: flex;
    font-size: 21px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 242px;
    bottom: 172px;
  }
  .park-btn {
    width: 160px;
    height: 42px;
  }
}
.pop {
  width: 100%;
  height: 100%;
  background: #00000060;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .pop-content {
    width: 620px;
    height: 308px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 10px 43px 0px rgba(136, 136, 136, 0.3);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      right: 19px;
      top: 17px;
      cursor: pointer;
      z-index: 1;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .top_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 620px;
      height: 50px;
      background: #b83138;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
      }
    }
    .row {
      display: flex;
      flex-direction: row;

      width: 620px;
      height: 228px;
      position: absolute;
      top: 50px;
      left: 0;
      img {
        width: 152px;
        height: 119px;
      }
    }
    .flex {
      flex: 1;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
    .news {
      width: 160px;
      height: 42px;
      background: linear-gradient(50deg, #b83037, #d24249);
      box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
      border-radius: 21px;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
    .news:hover {
      background: linear-gradient(50deg, #dd3b44, #f74f58);
      box-shadow: 0px 3px 6px 0px rgba(231, 62, 74, 0.33);
    }
    .old {
      width: 160px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #b83138;
      border-radius: 20px;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #b83138;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
    }
    .kx_text {
      font-size: 18px;
      color: #999999;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
